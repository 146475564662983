import { makeAutoObservable } from 'mobx';
import { TelemetryService } from '../services/TelemetryService';

class MissionTimeStore {
  timeZone: { zoneName: string; abbreviation: string } | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  async loadTimezone(lat: number, long: number) {
    if (!lat || !long || this.timeZone) {
      return;
    }
    const timezone = await TelemetryService.getTimeZoneFromLatLong(lat, long);
    this.setTimeZone(timezone);
  }

  setTimeZone(timezone: { zoneName: string; abbreviation: string } | null) {
    this.timeZone = timezone;
  }

  clearTimeZone() {
    this.timeZone = null;
  }
  getTimeZone() {
    return this.timeZone;
  }
}

const missionTimeStore = new MissionTimeStore();
export default missionTimeStore;
