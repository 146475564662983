import { useEffect, FC, useState } from 'react';
import { useWebRTC, Producer } from '../contexts/WebRtcContext';
import { gstWebRTCAPI } from '../third-party-lib/gstwebrtc-api/gstwebrtc-api';
import '../third-party-lib/gstwebrtc-api/index.js';

const ProducerManager: FC = () => {
  const { setAllProducers, setLatestProducer, latestProducer } = useWebRTC();

  useEffect(() => {
    listenForProducers();
    return () => {
      gstWebRTCAPI.unregisterAllProducersListeners();
    };
  }, []);

  const listenForProducers = () => {
    const producerListener = {
      producerAdded: (producer: Producer) => {
        setLatestProducer(producer);
        processAllProducers();
        console.log('Producer added: ' + producer.id);
      },
      producerRemoved: (producer: Producer) => {
        if (latestProducer && latestProducer.id === producer.id) {
          setLatestProducer(null);
        }
        processAllProducers();
        console.log('Producer removed: ' + producer.id);
      },
    };
    gstWebRTCAPI.registerProducersListener(producerListener);
  };

  const processAllProducers = () => {
    console.log('Processing all producers');
    const allProducers = gstWebRTCAPI.getAvailableProducers() as Producer[];
    setAllProducers(allProducers);
    console.log('All producers', allProducers);
  };

  return null;
};

export default ProducerManager;
