import { makeAutoObservable } from 'mobx';
import Telemetry from '../models/Telemetry';
import { TelemetryService } from '../services/TelemetryService';

class TelemetryStore {
  telemetryData: Telemetry | null = null;
  terrainAltitudeMeters: number | null = null;
  intervalId: NodeJS.Timeout | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  updateAltitude(terrainAltitudeMeters: number | null) {
    this.terrainAltitudeMeters = terrainAltitudeMeters;
  }

  updateTelemetry(telemetryData: Telemetry) {
    this.telemetryData = telemetryData;
  }

  setIntervalId(intervalId: NodeJS.Timeout) {
    this.intervalId = intervalId;
  }

  startCollectingTelemetry() {
    const intervalId = setInterval(() => {
      TelemetryService.getTelementryData().then((telemetryData) => {
        this.updateTelemetry(telemetryData);
      });
    }, 1000);
    this.setIntervalId(intervalId);
  }

  stopCollectingTelemetry() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }
}

const telemetryStore = new TelemetryStore();
export default telemetryStore;
