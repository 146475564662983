import ZoomMeeting from '../components/ZoomMeeting';

const MissionComms = () => {
  return (
    <div>
      <ZoomMeeting />
    </div>
  );
};
export default MissionComms;
