import { useEffect } from 'react';
import { SessionService } from '../services/SessionService';
import sessionStore from '../stores/SessionStore';
import { observer } from 'mobx-react-lite';

const CSRFLoader = () => {
  useEffect(() => {
    SessionService.getCSRFToken().then((resp) => {
      sessionStore.updateCsrfToken(resp.headers.get('X-CSRFToken') || '');
    });
  }, []);
  return null;
};

export default observer(CSRFLoader);
