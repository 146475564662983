import { Duration } from 'luxon';
import { useEffect, useState } from 'react';
import useGetLocalTime from '../../hooks/useGetLocalTime';
import telemetryStore from '../../stores/TelemetryStore';

const MissionTimer = () => {
  const { telemetryData } = telemetryStore;
  const { duration_since_arming_ms = 0 } = telemetryData || {};
  const { localTime } = useGetLocalTime();

  const formatTime = (timeInSeconds: number): string => {
    const duration = Duration.fromObject({ seconds: timeInSeconds });
    return duration.toFormat('hh:mm:ss');
  };
  return (
    <div className='flex flex-row gap-2  justify-center'>
      <div className='shrink'>
        <div className='flex flex-col gap-2  justify-center'>
          <div className='font-semibold'>Mission Time:</div>
          <div className='font-semibold'>Local Time:</div>
        </div>
      </div>
      <div className='shrink'>
        <div className={`text-right font-mono flex flex-col gap-2  justify-center`}>
          <div>{formatTime(duration_since_arming_ms / 1000)}</div>
          <div>{localTime}</div>
        </div>
      </div>
    </div>
  );
};

export default MissionTimer;
/* <div className='flex flex-col gap-2  justify-center'>
      <div>
        <DataLabel label='Mission Time: '  value={formatTime(time)} id='mission-time' />
      </div>
      <div>
        <DataLabel label='Local Time: '  value={localTime} id='local-time' />
      </div>
    </div> */
