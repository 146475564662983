import { useNavigate } from 'react-router-dom';
import { SessionService } from '../services/SessionService';
import sessionStore from '../stores/SessionStore';
import { useEffect } from 'react';

const SessionManager = () => {
  const navigate = useNavigate();
  const user = sessionStore.user;
  useEffect(() => {
    if (!user) {
      SessionService.getSession().then((resp) => {
        if (resp.login === true) {
          // load user and csrf token
          SessionService.getCSRFToken().then((resp) => {
            sessionStore.updateCsrfToken(resp.headers.get('X-CSRFToken') || '');
            SessionService.getUser().then((resp) => {
              sessionStore.updateUser(resp);
            });
          });
        } else {
          navigate('/');
        }
      });
    }
  }, [user]);

  return null;
};

export default SessionManager;
