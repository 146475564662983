import { useEffect, useRef } from 'react';
import { ZoomMeetingService } from '../services/ZoomMeetingService';
import sessionStore from '../stores/SessionStore';
import Button from '@mui/joy/Button';
import { observer } from 'mobx-react-lite';

const ZoomMeeting = () => {
  const token = sessionStore.csrfToken;
  const meetingRef = useRef<HTMLDivElement>(null);
  const userName = sessionStore.user?.username || '';

  useEffect(() => {
    if (token) {
      let meetingSDKElement = document.getElementById('meetingSDKElement') || undefined;
      const fetchZoomTokenAndJoinMeeting = async () => {
        const zoomToken = await ZoomMeetingService.getAuth();
        if (meetingSDKElement) {
          ZoomMeetingService.joinMeeting(zoomToken, meetingSDKElement, '', userName);
        }
      };

      fetchZoomTokenAndJoinMeeting();
    }
  }, [token]);

  useEffect(() => {
    if (!userName) {
      return;
    }

    getSignature();
  }, [userName]);

  const getSignature = async () => {
    const zoomToken = await ZoomMeetingService.getAuth();
    let meetingSDKElement = document.getElementById('meetingSDKElement') || undefined;
    if (meetingSDKElement) {
      ZoomMeetingService.joinMeeting(zoomToken.signature, meetingSDKElement, '', userName);
    }
  };

  return (
    <>
      <div id='meetingSDKElement' ref={meetingRef}></div>
    </>
  );
};

export default observer(ZoomMeeting);
