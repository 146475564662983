import Telemetry from '../../models/Telemetry';
import DataLabel from './DataLabel';
import { roundToDecimalPlaces } from '../../general-utilities/numbers-util';

export interface AllDroneDataCarouselProps {
  telemetryData?: Telemetry | null;
}

const AllDroneDataCarousel = ({ telemetryData }: AllDroneDataCarouselProps) => {
  return <CarouselSection telemetryData={telemetryData} />;
};

const CarouselSection = ({ telemetryData }: AllDroneDataCarouselProps) => {
  // Preprocess telemetry data for display, checking for null or undefined values
  const processedData = {
    battery:
      telemetryData?.battery?.remaining_percent !== undefined ? `${telemetryData.battery.remaining_percent}%` : '-',
    roll:
      telemetryData?.attitude_euler?.roll_deg !== undefined
        ? `${roundToDecimalPlaces(telemetryData.attitude_euler.roll_deg, 5)} deg`
        : '-',
    pitch:
      telemetryData?.attitude_euler?.pitch_deg !== undefined
        ? `${roundToDecimalPlaces(telemetryData.attitude_euler.pitch_deg, 5)} deg`
        : '-',
    yaw:
      telemetryData?.attitude_euler?.yaw_deg !== undefined
        ? `${roundToDecimalPlaces(telemetryData.attitude_euler.yaw_deg, 5)} deg`
        : '-',
  };

  return (
    <div className='flex flex-col justify-content-center mt-10 text-xl font-bold h-full'>
      <div>
        <DataLabel id='roll' label='Mission Timer:' value={'00:00:00'} />
      </div>
      <div>
        <DataLabel id='battery' label='Battery:' value={processedData.battery} />
      </div>
      {/* <div>
        <DataLabel id='roll' label='Roll:' value={processedData.roll} labelWidth='w-12' alignRight={true} />
      </div>
      <div>
        <DataLabel id='pitch' label='Pitch:' value={processedData.pitch} labelWidth='w-12' alignRight={true}/>
      </div>
      <div>
        <DataLabel id='yaw' label='Yaw:' value={processedData.yaw} labelWidth='w-12' alignRight={true}/>
      </div> */}
    </div>
  );
};

export default AllDroneDataCarousel;
