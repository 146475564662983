import mapboxgl from 'mapbox-gl';
import { Threebox, THREE } from 'threebox-plugin';
import { AttitudeEuler } from '../../models/Telemetry';

export type HexColor = number;

//
export const colorThreeObject = (object: any, color: HexColor) => {
  if (object.isMesh) {
    const newMaterial = object.material.clone(); // Clone the material since it doesn't seem to be possible to change the color of the original material
    newMaterial.color.setHex(color);
    object.material = newMaterial;
  }
};

export const addTerrainAndSky = (map: mapboxgl.Map) => {
  map.addSource('mapbox-dem', {
    type: 'raster-dem',
    url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
    tileSize: 512,
    maxzoom: 20,
  });
  map.setTerrain({ source: 'mapbox-dem', exaggeration: 1.1 });
  map.addLayer({
    id: 'sky',
    type: 'sky',
    paint: {
      'sky-type': 'gradient',
      'sky-gradient': [
        'interpolate',
        ['linear'],
        ['sky-radial-progress'],
        0,
        'rgba(135, 206, 235, 1)',
        1,
        'rgba(135, 206, 235, 1)',
      ],
      'sky-atmosphere-sun': [0.0, 90.0],
      'sky-atmosphere-sun-intensity': 15,
    },
  });
};

export const addRaisedLabels = (map: mapboxgl.Map) => {
  const layer = map.getStyle().layers.find((layer) => layer.id === 'natural-point-label') as mapboxgl.SymbolLayer;
  // Add in a pitch condition to the existing layers filter
  // so that it only renders when the pitch is low
  if (!layer) return;

  const lowPitchFilter = ['all', layer.filter, ['<', ['pitch'], 60]];
  map.setFilter('natural-point-label', lowPitchFilter);

  // Add in styling for leader lines into the layer
  layer.id = 'natural-point-label-elevated';
  // Add a leader line using `icon-image`
  if (!layer.layout) return;
  layer.layout['icon-image'] = 'leader_line';
  layer.layout['icon-anchor'] = 'bottom';
  // Elevate the text using text-offset
  layer.layout['text-offset'] = [0, -12.5];
  // Set the filter to only render at high pitch
  const highPitchFilter = ['all', layer.filter, ['>=', ['pitch'], 60]];
  layer.filter = highPitchFilter;

  // Add in a new layer with the updated styling
  map.addLayer(layer, 'natural-point-label');
};

export const initThreebox = (map: mapboxgl.Map) => {
  return new Threebox(map, map.getCanvas().getContext('webgl'), {
    realSunlight: true,
    defaultLights: true,
    terrain: true,
  });
};

export const initMap = (mapContainer: HTMLDivElement, startLng: number, startLat: number, zoom = 9) => {
  return new mapboxgl.Map({
    container: mapContainer,
    style: `mapbox://styles/mapbox-map-design/claitl3i0002715qm9990tl95`,
    center: [startLng, startLat],
    zoom: zoom,
    pitch: 60,
    bearing: 0,
  });
};

export const observeMapResize = (
  map: mapboxgl.Map,
  mapContainer: HTMLDivElement
): { resizeObserver: ResizeObserver; cleanup: () => void } => {
  const resizeObserver = new ResizeObserver(() => {
    if (map) {
      map.resize();
    }
  });

  resizeObserver.observe(mapContainer);
  const cleanup = () => {
    if (resizeObserver && mapContainer) {
      resizeObserver.unobserve(mapContainer);
    }
  };
  return { resizeObserver, cleanup };
};

// tb is a Threebox object. Currently there is no available type definition for this object.
export const makeTriangle = (tb: any) => {
  const triangleShape = new THREE.Shape();
  triangleShape.moveTo(0, 0);
  triangleShape.lineTo(-0.5, -1);
  triangleShape.lineTo(0, -0.7);
  triangleShape.lineTo(0.5, -1);
  triangleShape.lineTo(0, 0);

  const extrudeSettings = { depth: 0.1, bevelEnabled: false };
  const geometry = new THREE.ExtrudeGeometry(triangleShape, extrudeSettings);
  const material = new THREE.MeshPhongMaterial({
    color: 0xff0000,
    emissive: 0x3060a0,
    emissiveIntensity: 0.1,
  });

  const triangleMesh = new THREE.Mesh(geometry, material);
  const triangle = tb.Object3D({
    obj: triangleMesh,
    adjustment: { x: 0.45, y: 0.4, z: 0 },
    fixedZoom: 15,
    scale: 2,
  });
  return triangle;
};

export type ThreeDimCoords = [number, number, number];

export const configureDrone = (
  tb: any,
  drone: any,
  coords: ThreeDimCoords,
  map: mapboxgl.Map,
  attitude_euler: AttitudeEuler | undefined
) => {
  drone.setCoords(coords);
  if (attitude_euler) {
    drone.setRotation({
      x: -attitude_euler.pitch_deg,
      y: -attitude_euler.roll_deg,
      z: -attitude_euler.yaw_deg + 180,
    });
  }

  drone.traverse((child: any) => {
    if (child.isMesh) {
      colorThreeObject(child, 0x000000);
    }
  });
  drone.castShadow = true;
  drone.fixedZoom = 20;
  drone.setObjectScale((map as any).transform.scale);
  return drone;
};
