import { makeAutoObservable } from 'mobx';
import User from '../models/User';

class SessionStore {
  user: User | null = null;
  csrfToken: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  updateUser(user: User | null) {
    this.user = user;
  }

  updateCsrfToken(csrfToken: string | null) {
    this.csrfToken = csrfToken;
  }

  clearSession() {
    this.updateCsrfToken(null);
    this.updateUser(null);
  }
}

const sessionStore = new SessionStore();
export default sessionStore;
