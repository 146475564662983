import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useEffect, useRef } from 'react';
import telemetryStore from '../../stores/TelemetryStore';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import {
  addRaisedLabels,
  addTerrainAndSky,
  configureDrone,
  initMap,
  initThreebox,
  makeTriangle,
  observeMapResize,
} from './map-utils';
declare let window: any;

mapboxgl.accessToken = 'pk.eyJ1IjoianVsaWFub2VzIiwiYSI6ImNrOGNwbXVzbTA2bnMzZW95bmhvemIwbGkifQ.vHzrkhopow_IrtZm5yP5iA';

const MapBoxMap = () => {
  const mapContainer = useRef(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const drone = useRef<any | null>(null);
  const droneTriangle = useRef<any | null>(null);
  const droneFlightLineObj = useRef<any | null>(null);
  const droneFlightPathCoords = useRef<number[][]>([]);
  const firstPosition = useRef(true);
  const LNG = -121.98134;
  const LAT = 37.4174;
  const ZOOM = 9;

  useEffect(() => {
    if (!map.current && mapContainer.current) {
      map.current = initMap(mapContainer.current, LNG, LAT, ZOOM);
      map.current.addControl(new mapboxgl.NavigationControl());
      map.current.addControl(new mapboxgl.ScaleControl());

      map.current.on('load', () => {
        if (!map.current) {
          return;
        }
        addRaisedLabels(map.current);
      });

      map.current.on('style.load', () => {
        if (!map.current) {
          return;
        }
        window.tb = initThreebox(map.current);
        addTerrainAndSky(map.current);
      });
    }
  }, [map.current, mapContainer.current]);

  useEffect(() => {
    if (!mapContainer.current || !map.current) return;

    const { cleanup } = observeMapResize(map.current, mapContainer.current);
    return cleanup;
  }, [mapContainer.current]);

  useEffect(() => {
    // Subscribe to store changes
    const disposer = autorun(() => {
      const store = telemetryStore.telemetryData;
      const { latitude_deg, longitude_deg, absolute_altitude_m } = store?.position || {};
      const { attitude_euler } = store || {};
      const droneAltitudeOffset = 1;
      if (!latitude_deg || !longitude_deg || !map.current?.loaded()) return;

      if (firstPosition.current) {
        firstPosition.current = false;
        map.current.jumpTo({ center: [longitude_deg, latitude_deg], zoom: 15 });
        map.current.addLayer({
          id: 'drone',
          type: 'custom',
          renderingMode: '3d',
          onAdd: function (map) {
            // instantiate threebox
            const glb = window.location.origin + '/images/mcfly.glb';
            const options = {
              obj: glb,
              type: 'gltf',
              scale: 0.0001,
              rotation: { x: 0, y: 180, z: 0 },
              anchor: 'center',
              bbox: false,
            };

            let droneZ = droneAltitudeOffset;
            if (absolute_altitude_m) {
              droneZ += absolute_altitude_m;
            }

            window.tb.loadObj(
              options,
              (model: any) => {
                drone.current = configureDrone(
                  window.tb,
                  model,
                  [longitude_deg, latitude_deg, droneZ],
                  map,
                  attitude_euler
                );

                const triangle = makeTriangle(window.tb);
                triangle.setCoords([longitude_deg, latitude_deg, droneZ - 10]);
                droneTriangle.current = triangle;

                if (attitude_euler) {
                  droneTriangle.current.setRotation({
                    x: -attitude_euler.pitch_deg,
                    y: -attitude_euler.roll_deg - 5,
                    z: -attitude_euler.yaw_deg + 180,
                  });
                }

                droneFlightPathCoords.current.push([longitude_deg, latitude_deg, droneZ]);
                droneFlightPathCoords.current.push([longitude_deg - 0.000001, latitude_deg - 0.000001, droneZ]);

                droneFlightLineObj.current = window.tb.line({
                  geometry: droneFlightPathCoords.current,
                  color: '#9d0759',
                  width: 4,
                });

                window.tb.add(droneFlightLineObj.current);
                // window.tb.add(drone.current);
                window.tb.add(droneTriangle.current);
                const coordinates = {
                  lng: longitude_deg,
                  lat: latitude_deg,
                };

                telemetryStore.updateAltitude(map.queryTerrainElevation(coordinates));
              },
              (error: any) => {
                console.error('error', error);
              }
            );
          },

          render: function (gl, matrix) {
            window.tb.update();
          },
        });
      } else {
        map.current.flyTo({ center: [longitude_deg, latitude_deg] });

        const coords = [longitude_deg, latitude_deg, absolute_altitude_m || 0];

        const triangleCoords = [...coords.slice(0, 2), typeof coords[2] === 'number' ? coords[2] - 10 : 0];

        // Update drone position and rotation
        // if (!drone.current) return;
        // drone.current.setCoords(coords);
        droneTriangle.current.setCoords(triangleCoords);
        if (attitude_euler) {
          drone.current.setRotation({
            x: -attitude_euler.pitch_deg,
            y: -attitude_euler.roll_deg,
            z: -attitude_euler.yaw_deg + 180,
          });
          droneTriangle.current.setRotation({
            x: -attitude_euler.pitch_deg,
            y: -attitude_euler.roll_deg,
            z: -attitude_euler.yaw_deg + 180,
          });
        }
        // update flight path
        if (droneFlightLineObj.current) {
          window.tb.remove(droneFlightLineObj.current);
        }
        if (longitude_deg && latitude_deg && absolute_altitude_m) {
          droneFlightPathCoords.current.push([longitude_deg, latitude_deg, absolute_altitude_m]);
          droneFlightLineObj.current = window.tb.line({
            geometry: droneFlightPathCoords.current,
            color: 'red',
            width: 4,
          });
          window.tb.add(droneFlightLineObj.current);
        }
      }
    });

    return () => disposer();
  }, []);

  return (
    <div className='h-full'>
      <div ref={mapContainer} className='h-full' />
    </div>
  );
};

export default observer(MapBoxMap);
