import { SessionService } from '../services/SessionService';
import { useNavigate } from 'react-router-dom';
import sessionStore from '../stores/SessionStore';

const Header = () => {
  const navigate = useNavigate();
  const logout = async () => {
    SessionService.logout().then((resp) => {
      if (resp.logout === true) {
        sessionStore.clearSession();
        navigate('/');
      }
    });
  };
  return (
    <header className='py-4 border-b border-slate-900/10 lg:px-8 lg:border-0 dark:border-slate-300/10 mx-4 lg:mx-0'>
      <h1
        onClick={() => {
          logout();
        }}
      >
        Logout
      </h1>
    </header>
  );
};

export default Header;
