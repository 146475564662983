import './App.css';
import { Route, Routes } from 'react-router-dom';
import routes from './routes/routes';
import { CssBaseline, CssVarsProvider } from '@mui/joy';
import { WebRTCProvider } from './contexts/WebRtcContext';
import ProducerManager from './components/ProducerManager';
import CSRFLoader from './components/CRSFLoader';
import SessionManager from './components/SessionManager';

function App() {
  return (
    <WebRTCProvider>
      <SessionManager />
      <ProducerManager />
      <CssVarsProvider defaultMode='dark' disableTransitionOnChange>
        <CssBaseline />
        <CSRFLoader />
        <Routes>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={<route.component />} />
          ))}
        </Routes>
      </CssVarsProvider>
    </WebRTCProvider>
  );
}

export default App;
