import { useEffect, useState } from 'react';
import { SessionService } from '../services/SessionService';
import { useNavigate } from 'react-router-dom';
import sessionStore from '../stores/SessionStore';

interface FormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement;
  password: HTMLInputElement;
  persistent: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

export default function Login() {
  const [csrfToken, setCsrfToken] = useState<string>('');
  const navigate = useNavigate();
  useEffect(() => {
    SessionService.apiPing();
    SessionService.getSession().then((resp) => {
      console.log('session loading', resp);
      if (resp.login === true) {
        navigate('/inventory');
      } else {
        SessionService.getCSRFToken().then((resp) => {
          setCsrfToken(resp.headers.get('X-CSRFToken') || '');
          sessionStore.updateCsrfToken(resp.headers.get('X-CSRFToken') || '');
        });
      }
    });
  }, []);

  const login = async (email: string, password: string) => {
    const loginResp = await SessionService.login(email, password, csrfToken);
    if (loginResp.login === true) {
      const user = await SessionService.getUser();
      sessionStore.updateUser(user);
      navigate('/inventory');
    } else {
      alert('Login failed');
    }
  };

  return (
    <div className='flex items-center justify-center min-h-screen bg-black relative overflow-hidden'>
      <div className='bg-black w-full border-6 z-10 absolute top-0 flex items-center pl-6 flex-wrap'>
        <img className='h-12 w-auto' src='/images/logos/logo.png' alt='Sifly Logo' />
        <img className='h-20 w-auto' src='/images/logos/forwardArrow.png' alt='Sifly Arrow' />

        <span className='text-white  md:ml-6'>Intelligent Aerial Robotics</span>
      </div>

      <footer className='bg-black w-full border-6 z-10 absolute bottom-0 flex p-6 text-white'>
        <div>
          <p>© {new Date().getFullYear()} Sifly. All rights reserved.</p>
        </div>
      </footer>
      <video autoPlay muted loop className='absolute w-auto min-w-full min-h-full max-w-none'>
        <source src='/videos/siflylogin.mp4' type='video/mp4' />
        Your browser does not support the video tag.
      </video>

      <div className='w-full max-w-md p-8 space-y-8 bg-black bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg z-10 m-4'>
        <div className='text-center'>
          <h2 className='mt-6 text-3xl font-extrabold text-white'>Sign in to your account</h2>
        </div>
        <form
          className='mt-8 space-y-6'
          onSubmit={(event: React.FormEvent<SignInFormElement>) => {
            event.preventDefault();
            const formElements = event.currentTarget.elements;
            const data = {
              email: formElements.email.value,
              password: formElements.password.value,
              persistent: formElements.persistent.checked,
            };
            login(data.email, data.password);
          }}
        >
          <input type='hidden' name='remember' value='true' />
          <div className='rounded-md shadow-sm -space-y-px'>
            <div>
              <label htmlFor='email-address' className='sr-only'>
                Email address
              </label>
              <input
                id='email-address'
                name='email'
                autoComplete='email'
                required
                className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
                placeholder='Email address'
              />
            </div>
            <div>
              <label htmlFor='password' className='sr-only'>
                Password
              </label>
              <input
                id='password'
                name='password'
                type='password'
                autoComplete='current-password'
                required
                className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm'
                placeholder='Password'
              />
            </div>
          </div>

          <div className='flex items-center justify-between'>
            <div className='flex items-center'>
              <input
                id='remember-me'
                name='persistent'
                type='checkbox'
                className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
              />
              <label htmlFor='remember-me' className='ml-2 block text-sm text-white'>
                Remember me
              </label>
            </div>

            <div className='text-sm'>
              <a href='#' className='font-medium text-blue-400 hover:text-blue-400'>
                Forgot your password?
              </a>
            </div>
          </div>

          <div>
            <button
              type='submit'
              className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
