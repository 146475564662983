import telemetryStore from '../../../stores/TelemetryStore';
import LocalAddress from '../LocalAddress';
import LatLongComponent from './LatLongComponent';

const LocationContainer = () => {
  const { telemetryData } = telemetryStore;
  if (!telemetryData) {
    return null;
  }
  return (
    <div className='flex  w-full'>
      <div className='flex flex-col gap-2  justify-center'>
        <div>
          <LocalAddress
            lat={telemetryData?.position.latitude_deg?.toString()}
            long={telemetryData?.position.longitude_deg?.toString()}
          />
        </div>
        <div>
          <LatLongComponent lat={telemetryData?.position.latitude_deg} long={telemetryData?.position.longitude_deg} />
        </div>
      </div>
    </div>
  );
};

export default LocationContainer;
