import Login from '../pages/Login';
import Locations from '../pages/Locations';
import Inventory from '../pages/Inventory';
import DroneDetails from '../pages/DroneDetails';
import DroneMission from '../pages/DroneMission';
import MissionView from '../pages/MissionView';
import TestLayout from '../pages/TestLayout';
import MissionView2 from '../pages/MissionView2';
import MissionComms from '../pages/MissionComms';

const routes = [
  {
    path: '/',
    component: Login,
    name: 'Login',
  },
  {
    path: '/locations',
    component: Locations,
    name: 'Locations',
  },
  {
    path: '/inventory',
    component: Inventory,
    name: 'Inventory',
  },
  {
    path: '/drone-details',
    component: DroneDetails,
    name: 'Drone Details',
  },
  {
    path: '/drone-mission',
    component: DroneMission,
    name: 'Drone Mission',
  },
  {
    path: '/mission-view/:id',
    component: MissionView,
    name: 'Drone Mission View',
  },
  {
    path: '/mission-view2/:id',
    component: MissionView2,
    name: 'Drone Mission View',
  },
  {
    path: '/mission-comms',
    component: MissionComms,
    name: 'Mission Communications',
  },
  {
    path: '/test-layout',
    component: TestLayout,
    name: 'test layout',
  },
];

export default routes;
