import { useRef, useEffect } from 'react';

interface ThermalVideoProps {
  stream: MediaStream | null;
}

const ThermalVideo = ({ stream }: ThermalVideoProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(document.createElement('video'));

  /*     useEffect(() => {
      adjustCanvasSize();
      // Adjust canvas size on window resize
      window.addEventListener('resize', adjustCanvasSize);
      return () => window.removeEventListener('resize', adjustCanvasSize);
    }, []); */

  useEffect(() => {
    if (stream && canvasRef.current !== null && videoRef.current !== null) {
      adjustCanvasSize();
      videoRef.current.srcObject = stream;
      videoRef.current.muted = true; // Mute the video
      videoRef.current.play().catch((err) => console.error('Video play failed', err));
      const ctx = canvasRef.current.getContext('2d');
      if (ctx) {
        const drawFrame = () => {
          if (videoRef.current.ended || videoRef.current.paused || !canvasRef.current) return;
          ctx.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
          // Get image data
          const imageData = ctx.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height);
          const data = imageData.data;

          // Apply a simple thermal effect
          for (let i = 0; i < data.length; i += 4) {
            const brightness = 0.34 * data[i] + 0.5 * data[i + 1] + 0.16 * data[i + 2];
            // Thermal effect: simple mapping based on brightness
            data[i] = brightness < 85 ? 0 : brightness; // Red
            data[i + 1] = brightness < 85 ? 2 * brightness : brightness < 170 ? brightness - 85 : 0; // Green
            data[i + 2] = brightness < 85 ? 255 : 0; // Blue
          }

          // Put the image data back after manipulation
          ctx.putImageData(imageData, 0, 0);

          requestAnimationFrame(drawFrame);
        };
        drawFrame();
      }
    }
  }, [stream, canvasRef.current, videoRef.current]);

  const adjustCanvasSize = () => {
    const canvas = canvasRef.current;
    if (canvas && canvas.parentElement) {
      // Set the canvas dimensions to match the parent element's computed styles
      const { width, height } = getComputedStyle(canvas.parentElement);
      const parentWidth = parseInt(width, 10);
      const parentHeight = parseInt(height, 10);
      console.log('Parent dimensions:', parentWidth, parentHeight);
      // Calculate aspect ratio constrained dimensions
      const aspectRatio = 16 / 9;
      let newWidth = parentWidth;
      let newHeight = parentWidth / aspectRatio;

      // If the calculated height is greater than the parent's height, adjust based on height
      if (newHeight > parentHeight) {
        newHeight = parentHeight;
        newWidth = newHeight * aspectRatio;
      }

      // Set the canvas dimensions
      canvas.width = newWidth;
      canvas.height = newHeight;
      console.log('Canvas dimensions:', newWidth, newHeight);
    }
  };

  return (
    <div className=' w-full  ' style={{ height: '320px' }}>
      <canvas ref={canvasRef} />
    </div>
  );
};

export default ThermalVideo;
