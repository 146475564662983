import useGetLocalTime from '../../hooks/useGetLocalTime';

const LocalDateTimeDisplay = ({ lat, long }: { lat?: string; long?: string }) => {
  const { localTime } = useGetLocalTime();
  return (
    <div>
      <p>{localTime}</p>
    </div>
  );
};

export default LocalDateTimeDisplay;
