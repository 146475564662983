import ZoomMtgEmbedded from '@zoom/meetingsdk/embedded';
import sessionStore from '../stores/SessionStore';

const MEETING_NUMBER = '7731350575';
const MEETING_PASSWORD = 'FnsUv7';
const client = ZoomMtgEmbedded.createClient();

const getAuth = () => {
  return fetch('/api/zoommeeting-auth', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': sessionStore.csrfToken || '',
    },
    body: JSON.stringify({
      meetingNumber: '7731350575',
      role: 0,
      expirationsSeconds: 600,
    }),
  }).then((res) => res.json());
};

const joinMeeting = (
  signature: string,
  meetingSDKElement: HTMLElement,
  userEmail: string = '',
  userName: string = ''
) => {
  console.log('joining meeting');
  console.log('signature', process.env.REACT_APP_ZOOM_SDK_KEY);
  console.log('user:', userEmail, userName);
  client
    .init({
      zoomAppRoot: meetingSDKElement,
      language: 'en-US',
      patchJsMedia: true,
      customize: {
        video: {
          isResizable: true,
          viewSizes: { default: { width: 1280, height: 720 } },
        },
      },
    })
    .then(() => {
      client
        .join({
          signature: signature,
          sdkKey: process.env.REACT_APP_ZOOM_SDK_KEY,
          meetingNumber: MEETING_NUMBER,
          password: MEETING_PASSWORD,
          userName: userName,
          userEmail: userEmail,
        })
        .then(() => {
          console.log('joined successfully');
        })
        .catch((error) => {
          console.log(error);
        });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const ZoomMeetingService = {
  getAuth,
  joinMeeting,
};
