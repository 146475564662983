import { useEffect, useState } from 'react';
import MapBoxMap from '../components/Map/MapBoxMap';
import ThermalVideo from '../components/ThermalVideo';
import { useWebRTC } from '../contexts/WebRtcContext';
import VideoDisplay from '../components/VideoDisplay';
import telemetryStore from '../stores/TelemetryStore';
import { observer } from 'mobx-react-lite';
import Split from '@uiw/react-split';
import MissionViewHeaderV2 from '../components/MissionView/MissionViewHeaderV2';

export enum MainContentDisplayLayout {
  Single,
  Dual,
  Triple,
  Quad,
}

const MissionView2 = () => {
  const droneName = 'Drone 1';
  const [videoHeight, setVideoHeight] = useState(0); // State to store video height
  const [stream, setStream] = useState<MediaStream | null>(null); // State to store the video stream for the thermal camera
  const { latestProducer, getStream } = useWebRTC();
  const baseHeaderHeight = 120; // Base height of the header
  const [headerHeight, setHeaderHeight] = useState(baseHeaderHeight); // State to store the height of the header

  const processStream = async (producerId: string) => {
    try {
      const stream = await getStream(producerId);
      setStream(stream);
    } catch (error) {
      console.error('Error processing stream', error);
    }
  };

  useEffect(() => {
    telemetryStore.startCollectingTelemetry();

    return () => {
      telemetryStore.stopCollectingTelemetry();
    };
  }, []);

  useEffect(() => {
    if (latestProducer) {
      processStream(latestProducer.id);
    }
  }, [latestProducer]);

  useEffect(() => {
    const calculateVideoHeight = () => {
      // Calculate the available height for the video
      const windowHeight = window.innerHeight;
      const estimatedHeaderHeight = windowHeight * 0.125;
      if (estimatedHeaderHeight > baseHeaderHeight) {
        setHeaderHeight(estimatedHeaderHeight);
      }
      const totalOtherElementsHeight = estimatedHeaderHeight; // Adjust based on your fixed heights and gaps
      const availableHeight = windowHeight - totalOtherElementsHeight - 4 * 2; // Subtracting the total height of other elements and gaps (assuming gap-4 => 16px total)
      setVideoHeight(availableHeight);
    };

    // Call the function to set initial video height
    calculateVideoHeight();

    // Add event listener to adjust video height on window resize
    window.addEventListener('resize', calculateVideoHeight);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', calculateVideoHeight);
  }, []);

  return (
    <div className='grid grid-rows-2  h-screen w-full' style={{ gridTemplateRows: `${headerHeight}px 1fr` }}>
      {/*   <!-- Row 1: Header or any content --> */}
      <div className=' '>
        <MissionViewHeaderV2
          droneName={droneName}
          addressString=''
          lat={telemetryStore.telemetryData?.position.latitude_deg.toString()}
          long={telemetryStore.telemetryData?.position.longitude_deg.toString()}
          roll={telemetryStore.telemetryData?.attitude_euler.roll_deg}
          pitch={telemetryStore.telemetryData?.attitude_euler.pitch_deg}
        />
      </div>

      <div className='row-span-1 col-span-full  flex flex-column justify-items-middle '>
        <div className='w-full'>
          <Split style={{ height: '100%', width: '100%', border: '2px solid #393939' }}>
            <div style={{ flex: 1 }}>
              <VideoDisplay
                stream={stream}
                className='mx-auto'
                style={{ height: `${videoHeight}px` }}
                showTargetButton={true}
              />
            </div>
            <Split mode='vertical' style={{ width: '33%' }}>
              <div style={{ height: '50%' }}>
                {' '}
                <MapBoxMap />
              </div>
              <div style={{ height: '50%', overflow: 'hidden' }}>
                <ThermalVideo stream={stream} />
              </div>
            </Split>
          </Split>
        </div>
      </div>
    </div>
  );
};

export default observer(MissionView2);
