import IconButton from '@mui/joy/IconButton';
import { FC } from 'react';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import SouthWestIcon from '@mui/icons-material/SouthWest';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import NorthWestIcon from '@mui/icons-material/NorthWest';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';

export enum Direction {
  UP = 'up',
  DOWN = 'down',
  LEFT = 'left',
  RIGHT = 'right',
  UP_LEFT = 'up-left',
  UP_RIGHT = 'up-right',
  DOWN_LEFT = 'down-left',
  DOWN_RIGHT = 'down-right',
  CENTER = 'center',
}

interface DirectionButtonProps {
  direction: Direction;
  label?: string; // Optional label prop to customize button text
  className?: string;
  handleClick?: (direction: Direction) => void;
}

const DirectionButton: FC<DirectionButtonProps> = ({ direction, label, className, handleClick }) => {
  // Determine button label based on the direction or use the provided label
  const buttonText = label || direction;
  // Determine button icon based on the direction
  const buttonIcon = () => {
    switch (direction) {
      case Direction.UP:
        return <NorthIcon />;
      case Direction.DOWN:
        return <SouthIcon />;
      case Direction.LEFT:
        return <WestIcon />;
      case Direction.RIGHT:
        return <EastIcon />;
      case Direction.UP_LEFT:
        return <NorthWestIcon />;
      case Direction.UP_RIGHT:
        return <NorthEastIcon />;
      case Direction.DOWN_LEFT:
        return <SouthWestIcon />;
      case Direction.DOWN_RIGHT:
        return <SouthEastIcon />;
      case Direction.CENTER:
        return <CenterFocusWeakIcon />;
      default:
        return null;
    }
  };
  return (
    <IconButton
      color='neutral'
      className={className}
      variant='solid'
      onClick={() => {
        handleClick && handleClick(direction);
      }}
    >
      {buttonIcon()}
    </IconButton>
  );
};

export default DirectionButton;
