export enum SpeedUnit {
  KILOMETERS_PER_HOUR = 'km/h',
  MILES_PER_HOUR = 'mph',
  METERS_PER_SECOND = 'm/s',
}

// Conversion rates based on 1 meter/second
const conversionRates = new Map<SpeedUnit, number>([
  [SpeedUnit.METERS_PER_SECOND, 1],
  [SpeedUnit.KILOMETERS_PER_HOUR, 3.6],
  [SpeedUnit.MILES_PER_HOUR, 2.23694],
]);

export interface convertSpeedProps {
  speed: number;
  fromUnit: SpeedUnit;
  toUnit: SpeedUnit;
}

const convertSpeed = ({ speed, fromUnit, toUnit }: convertSpeedProps): number => {
  const fromRate = conversionRates.get(fromUnit);
  const toRate = conversionRates.get(toUnit);
  if (fromRate === undefined || toRate === undefined) {
    throw new Error('Invalid speed unit');
  }
  const speedInMetersPerSecond = speed / fromRate;
  return speedInMetersPerSecond * toRate;
};

export { convertSpeed };
