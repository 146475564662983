import React from 'react';

interface DataLabelProps {
  label: string | React.ReactNode;
  value?: string | number;
  id: string;
  alignRight?: boolean; // Optional prop to control text alignment
  labelWidth?: string;
  unit?: string;
  width?: string;
}

const DataLabel = ({ label, value, id, alignRight = false, labelWidth = '', unit, width = 'w-36' }: DataLabelProps) => {
  return (
    <div className='flex justify-start items-center'>
      <div className={`pr-1 ${labelWidth}`} id={id}>
        <span className='font-semibold'>{label}</span>
      </div>
      {/* Apply conditional class or style based on alignRight prop and use width prop */}
      <div className={`${alignRight ? 'text-right font-mono' : ''} ${width}`}>
        <span>{value}</span>
        {unit && <span className='text-sm ml-1' style={{ fontSize: '0.75em' }}>{`(${unit})`}</span>}
      </div>
    </div>
  );
};

export default DataLabel;
