import Telemetry from '../../models/Telemetry';
import DataLabel from './DataLabel';
import { UnitConversions, roundToDecimalPlaces } from '../../general-utilities/numbers-util';

export interface HighlightedDroneDataProps {
  telemetryData?: Telemetry | null;
}

const HighlightedDroneData = ({ telemetryData }: HighlightedDroneDataProps) => {
  // Preprocess telemetry data for display
  const milesPerHour = UnitConversions.mpsToMph(telemetryData?.ground_speed_m_s);
  const altitudeFeet = UnitConversions.mToF(telemetryData?.position.relative_altitude_m);

  const processedData = telemetryData
    ? {
        relativeAltitude:
          telemetryData.position.relative_altitude_m !== undefined
            ? roundToDecimalPlaces(altitudeFeet, 2).toFixed(2)
            : '-',
        groundSpeed:
          telemetryData.ground_speed_m_s !== undefined ? roundToDecimalPlaces(milesPerHour, 2).toFixed(2) : '-',
        remainingFlightTime:
          telemetryData.remaining_flight_time !== undefined
            ? roundToDecimalPlaces(telemetryData.remaining_flight_time, 5).toString()
            : '-',
        latitude:
          telemetryData.position.latitude_deg !== undefined
            ? roundToDecimalPlaces(telemetryData.position.latitude_deg, 5).toString()
            : '-',
        longitude:
          telemetryData.position.longitude_deg !== undefined
            ? roundToDecimalPlaces(telemetryData.position.longitude_deg, 5).toString()
            : '-',
      }
    : {};

  return (
    <div className='flex items-center w-full justify-between flex-wrap pr-6 pl-6 bg-midGray text-white'>
      <div className='flex gap-8'>
        <div>
          <DataLabel id='agl' label='AGL:' value={processedData.relativeAltitude} unit='ft' />
        </div>
        <div>
          <DataLabel id='gs' label='GS:' value={processedData.groundSpeed} unit='mph' />
        </div>
        <div>
          <DataLabel id='as' label='AS:' value={'-'} />
        </div>
      </div>
      <div>
        <DataLabel
          id='remaining-flight-time'
          label='Remaining flight time:'
          value={processedData.remainingFlightTime}
        />
      </div>
      <div className='flex gap-8'>
        <div>
          <DataLabel id='lat' label='Lat:' value={processedData.latitude} />
        </div>
        <div>
          <DataLabel id='long' label='Lon:' value={processedData.longitude} />
        </div>
      </div>
    </div>
  );
};

export default HighlightedDroneData;
