import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import missionTimeStore from '../stores/MissionTimeStore';

export interface LocalDateTimeDisplayProps {
  lat?: string;
  long?: string;
}

const useGetLocalTime = () => {
  const timeZone = missionTimeStore.getTimeZone();

  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);
  const [localTime, setLocalTime] = useState<string>('');
  const [localDate, setLocalDate] = useState<string>('');

  useEffect(() => {
    if (!timeZone || intervalId) {
      return;
    }

    const id = setInterval(() => {
      const e = DateTime.now().setZone(timeZone.zoneName).toFormat('HH:mm:ss');
      const f = DateTime.now().setZone(timeZone.zoneName).toFormat('yyyy-MM-dd');
      setLocalTime(e);
      setLocalDate(f);
    }, 1000);

    setIntervalId(id);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [timeZone]);

  return { localTime, localDate };
};

export default useGetLocalTime;
