// import { unit, complex, subtract, abs } from "mathjs";

const roundToDecimalPlaces = (value: number, decimalPlaces: number): number => {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(value * factor) / factor;
};

const metersToFeet = (meters?: number): number => {
  if (meters === undefined) {
    return 0;
  }
  return meters * 3.28084;
};

const metersPerSecondToMilesPerHour = (metersPerSecond?: number): number => {
  if (metersPerSecond === undefined) {
    return 0;
  }
  return metersPerSecond * 2.23694;
};

const metersPerSecondToKilometersPerHour = (metersPerSecond?: number): number => {
  if (metersPerSecond === undefined) {
    return 0;
  }
  return metersPerSecond * 3.6;
};

const kmhToMS = (kmh: number): number => {
  return kmh / 3.6;
};

const kmhToMph = (kmh: number): number => {
  const milesPerHour = kmh * 0.621371;
  return milesPerHour;
};

export type Speed = {
  value: number;
  unit: 'km/h' | 'mph' | 'm/s';
};

interface GroundSpeedToAirSpeedProps {
  groundSpeed: Speed;
  windSpeed: Speed;
  windDirectionDeg: number; // degrees
  droneHeading: number; // degrees
}
const areSameUnits = (speed1: Speed, speed2: Speed): boolean => {
  return speed1.unit === speed2.unit;
};

const simpleGroundSpeedToAirSpeed = ({
  groundSpeed,
  windSpeed,
  windDirectionDeg,
  droneHeading,
}: GroundSpeedToAirSpeedProps) => {
  if (!areSameUnits(groundSpeed, windSpeed)) {
    throw new Error('Speed units do not match');
  }
  const headingRadians = ((droneHeading - windDirectionDeg) * Math.PI) / 180;

  // Calculate the airspeed using the provided formula
  const airSpeed = groundSpeed.value + windSpeed.value * Math.cos(headingRadians);

  return airSpeed;
};

const UnitConversions = {
  mToF: metersToFeet,
  mpsToMph: metersPerSecondToMilesPerHour,
  gsToAs: simpleGroundSpeedToAirSpeed,
  mpsToKmh: metersPerSecondToKilometersPerHour,
  kmhToMph: kmhToMph,
  kmhToMs: kmhToMS,
};
/*
 * Normalize degrees to be between 0 and 360
 * since the backend returns values between -180 and 180
 */
const normalizeDegrees = (degrees: number): number => {
  return (degrees + 360) % 360;
};

export { roundToDecimalPlaces, UnitConversions, normalizeDegrees };
