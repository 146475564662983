import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import telemetryStore from '../stores/TelemetryStore';

const TestLayout: React.FC = () => {
  const [newMessage, setNewMessage] = useState<string>('');

  useEffect(() => {
    const socket = new WebSocket('ws://localhost:8444');
    debugger;
  }, []);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewMessage(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    telemetryStore.stopCollectingTelemetry();
  };

  return (
    <div>
      <p>{JSON.stringify(telemetryStore.telemetryData)}</p>

      <button onClick={() => telemetryStore.stopCollectingTelemetry()}>Stop Collecting Telemetry</button>
      <br />
      <button onClick={() => telemetryStore.startCollectingTelemetry()}>Start Collecting Telemetry</button>
    </div>
  );
};

export default observer(TestLayout);
