import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import MapBoxMap from '../components/Map/MapBoxMap';
import ThermalVideo from '../components/ThermalVideo';
import CameraDirectionPad from '../components/CameraRemoteControl/CameraDirectionPad';
import { IconButton } from '@mui/joy';
import FeaturedVideoIcon from '@mui/icons-material/FeaturedVideo';
import GridViewIcon from '@mui/icons-material/GridView';
import { useWebRTC } from '../contexts/WebRtcContext';
import VideoDisplay from '../components/VideoDisplay';
import telemetryStore from '../stores/TelemetryStore';
import HighlightedDroneData from '../components/MissionView/HighlitedDroneData';
import { observer } from 'mobx-react-lite';
import AllDroneDataCarousel from '../components/MissionView/AllDroneDataCarousel';
import MissionViewHeader from '../components/MissionView/MissionViewHeader';

export enum MainContentDisplayLayout {
  Single,
  Dual,
  Triple,
  Quad,
}

const MissionView = () => {
  const droneName = 'Drone 1';
  const [videoHeight, setVideoHeight] = useState(0); // State to store video height
  const [stream, setStream] = useState<MediaStream | null>(null); // State to store the video stream for the thermal camera
  const { latestProducer, getStream, stopConsumerSession } = useWebRTC();
  const [displayLayout, setDisplayLayout] = useState<MainContentDisplayLayout>(MainContentDisplayLayout.Single);

  const processStream = async (producerId: string) => {
    try {
      const stream = await getStream(producerId);
      setStream(stream);
    } catch (error) {
      console.error('Error processing stream', error);
    }
  };

  useEffect(() => {
    telemetryStore.startCollectingTelemetry();

    return () => {
      telemetryStore.stopCollectingTelemetry();
      stopConsumerSession();
    };
  }, []);

  useEffect(() => {
    if (latestProducer) {
      processStream(latestProducer.id);
    }

    return () => {
      stopConsumerSession();
    };
  }, [latestProducer]);

  useEffect(() => {
    const calculateVideoHeight = () => {
      // Calculate the available height for the video
      const totalOtherElementsHeight = 100 + 100 + 70 + 20; // Adjust based on your fixed heights and gaps
      const availableHeight = window.innerHeight - totalOtherElementsHeight - 4 * 4; // Subtracting the total height of other elements and gaps (assuming gap-4 => 16px total)
      setVideoHeight(availableHeight);
    };

    // Call the function to set initial video height
    calculateVideoHeight();

    // Add event listener to adjust video height on window resize
    window.addEventListener('resize', calculateVideoHeight);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', calculateVideoHeight);
  }, []); // Empty dependency array means this effect runs once on mount

  const getDisplayLayout = (layout: MainContentDisplayLayout) => {
    switch (layout) {
      case MainContentDisplayLayout.Single:
        return <VideoDisplay stream={stream} className='mx-auto' style={{ height: `${videoHeight}px` }} />;
      case MainContentDisplayLayout.Dual:
        return (
          <div className='flex w-full '>
            <div className='w-3/4 border'>
              <div className='relative' style={{ paddingTop: '56.25%' }}>
                <VideoDisplay stream={stream} className='absolute top-0 left-0 w-full h-full' />
              </div>
            </div>

            <div className='w-1/4 border h-full'>
              <MapBoxMap />
            </div>
          </div>
        );
      case MainContentDisplayLayout.Triple:
        return (
          <div className='flex w-full'>
            <div className='w-1/2 border'>
              <VideoDisplay stream={stream} className='w-full h-full' />
            </div>
            <div className='w-1/4 border'>
              <MapBoxMap />
            </div>
            <div className='w-1/4 border'>
              <ThermalVideo stream={stream} />
            </div>
          </div>
        );
      case MainContentDisplayLayout.Quad:
        return (
          <div className='grid grid-rows-2 grid-cols-2 gap-4 border h-full '>
            <div className=''>
              <VideoDisplay stream={stream} className='w-1/2' />
            </div>
            <div className='w-full'>
              <MapBoxMap />
            </div>
            <div className='w-full'>
              <ThermalVideo stream={stream} />
            </div>
            <div className=''>second camera</div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className='grid grid-rows-4  h-screen w-full ' style={{ gridTemplateRows: '4rem 1fr 48px 150px' }}>
      {/*   <!-- Row 1: Header or any content --> */}
      <div className=' '>
        <MissionViewHeader
          droneName={droneName}
          addressString=''
          lat={telemetryStore.telemetryData?.position.latitude_deg.toString()}
          long={telemetryStore.telemetryData?.position.longitude_deg.toString()}
        />
      </div>

      <div className='row-span-1 col-span-full  flex flex-column justify-items-middle '>
        <div className=' border-gray-300 m-auto flex w-full'>{getDisplayLayout(displayLayout)}</div>
      </div>

      {/* <!-- Row 3: Additional UI Elements --> */}
      <div className='row-span-1 col-span-full  flex align-middle'>
        <HighlightedDroneData telemetryData={telemetryStore.telemetryData} />
      </div>

      {/*   <!-- Row 4: Bottom row with 5 columns including controls and two video feeds --> */}
      <div className='grid grid-cols-5 col-span-full gap-4 ' style={{ gridTemplateColumns: '1fr 1fr 60px 1fr 1fr' }}>
        {/* <!-- Controls (Columns 1-3) --> */}
        <div className='col-span-1  pl-6 overflow-hidden'>
          <AllDroneDataCarousel telemetryData={telemetryStore.telemetryData} />
        </div>
        <div className='col-span-1  '>
          <div className='flex justify-items-center h-full w-full flex-row'>
            <div className='m-auto'>
              <CameraDirectionPad />
            </div>
          </div>
        </div>
        <div className='col-span-1 overflow-hidden flex flex-col pt-2 pb-2 gap-2'>
          <IconButton
            onClick={() => {
              setDisplayLayout(MainContentDisplayLayout.Single);
            }}
          >
            <img src='/images/icons/ic_1box.png' width='40px' alt='Single Video Display' />
          </IconButton>
          <IconButton
            onClick={() => {
              setDisplayLayout(MainContentDisplayLayout.Dual);
            }}
          >
            <img src='/images/icons/ic_2box.png' width='40px' alt='Dual Video Display' />
          </IconButton>
          <IconButton
            onClick={() => {
              setDisplayLayout(MainContentDisplayLayout.Triple);
            }}
          >
            <img src='/images/icons/ic_4box.png' width='40px' alt='Quad Video Display' />
          </IconButton>
        </div>

        {/* <!-- Video Feeds (Columns 4-5) --> */}
        <div className='col-span-1  overflow-hidden pb-2'>
          <ThermalVideo stream={stream} />
        </div>
        <div className='col-span-1 pb-2 pr-2'>
          <MapBoxMap />
        </div>
      </div>
    </div>
  );
};

export default observer(MissionView);
