import { useEffect, useState } from 'react';
import { TelemetryService } from '../../services/TelemetryService';

const LocalAddress = ({ lat, long }: { lat: string | undefined; long: string | undefined }) => {
  const [address, setAddress] = useState<string | null>(null);
  const [lastCoords, setLastCoords] = useState<{ lat: string | undefined | null; long: string | undefined | null }>({
    lat: null,
    long: null,
  });
  const [lastApiCall, setLastApiCall] = useState<number>(0);
  const hasMovedSignificantly = (
    lat1: string,
    long1: string,
    lat2: string | null,
    long2: string | null,
    threshold = 0.001
  ) => {
    if (lat2 === null || long2 === null) {
      // If the previous coordinates are null, assume significant move.
      return true;
    }
    return Math.abs(Number(lat1) - Number(lat2)) > threshold || Math.abs(Number(long1) - Number(long2)) > threshold;
  };
  useEffect(() => {
    const now = new Date().getTime();
    if (lat && long && now - lastApiCall >= 10000) {
      if (!lastCoords.lat || !lastCoords.long || hasMovedSignificantly(lat, long, lastCoords.lat, lastCoords.long))
        TelemetryService.getAddressFromLatLong(lat, long).then((data) => {
          if (data.features.length > 0) {
            setAddress(data.features[0].place_name);
            setLastCoords({ lat, long });
            setLastApiCall(new Date().getTime());
          }
        });
    }
  }, [lat, long]);

  return <div>{address}</div>;
};
export default LocalAddress;
