const Footer = () => {
  return (
    <footer className='text-sm leading-6 mt-12'>
      <div className='pt-10 pb-28 border-t border-slate-200 sm:flex justify-between text-slate-500 dark:border-slate-200/5'>
        <div className='mb-6 sm:mb-0 sm:flex'>
          <p>Copyright © 2024 SiFly</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
