import { makeAutoObservable } from 'mobx';
import { TelemetryService } from '../services/TelemetryService';
import OpenMeteoData from '../models/Weather';

class WeatherDataStore {
  latitute: number | string | null = null;
  longitude: number | string | null = null;
  weatherData: OpenMeteoData | null = null;
  poller: NodeJS.Timeout | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  updateLatLong({ latitute, longitude }: { latitute: number | string | null; longitude: number | string | null }) {
    this.latitute = latitute;
    this.longitude = longitude;
  }

  loadWeatherData() {
    if (this.latitute && this.longitude) {
      TelemetryService.getWeatherData(this.latitute, this.longitude).then((data) => {
        this.weatherData = data;
      });
    }
  }

  pollWeatherData() {
    if (this.poller) {
      clearInterval(this.poller);
    }
    this.poller = setInterval(() => {
      this.loadWeatherData();
    }, 60000);
  }

  cleanup() {
    if (this.poller) {
      clearInterval(this.poller);
    }
    this.poller = null;
    this.latitute = null;
    this.longitude = null;
    this.weatherData = null;
  }
}

const weatherDataStore = new WeatherDataStore();
export default weatherDataStore;
