import { AttitudeIndicator } from 'react-typescript-flight-indicators';
import telemetryStore from '../../../stores/TelemetryStore';
import weatherDataStore from '../../../stores/WeatherDataStore';
import {
  Speed,
  UnitConversions,
  normalizeDegrees,
  roundToDecimalPlaces,
} from '../../../general-utilities/numbers-util';
import DataLabel from '../DataLabel';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { SpeedUnit, convertSpeed } from '../../../general-utilities/units-util';

const AttitudeContainer = () => {
  const { telemetryData } = telemetryStore;
  const [airSpeed, setAirSpeed] = useState<number | undefined>();
  const { weatherData } = weatherDataStore;

  useEffect(() => {
    weatherDataStore.pollWeatherData();
    return () => {
      weatherDataStore.cleanup();
    };
  }, []);

  useEffect(() => {
    if (telemetryData?.position.latitude_deg && telemetryData?.position.longitude_deg) {
      weatherDataStore.updateLatLong({
        latitute: telemetryData.position.latitude_deg,
        longitude: telemetryData.position.longitude_deg,
      });
    }
  }, [telemetryData]);

  useEffect(() => {
    if (!telemetryData?.ground_speed_m_s || !telemetryData?.attitude_euler.yaw_deg || !weatherData) {
      return;
    }
    const windSpeedKmh = weatherData?.current.wind_speed_10m;
    const windDirection = weatherData?.current.wind_direction_10m;
    const groundSpeed: Speed = {
      value: telemetryData.ground_speed_m_s,
      unit: 'm/s',
    };
    const windSpeed: Speed = {
      value: convertSpeed({
        speed: windSpeedKmh,
        fromUnit: SpeedUnit.KILOMETERS_PER_HOUR,
        toUnit: SpeedUnit.METERS_PER_SECOND,
      }),
      unit: 'm/s',
    };

    const heading = normalizeDegrees(telemetryData?.heading_deg);
    const airSpeed = UnitConversions.gsToAs({
      groundSpeed,
      windSpeed,
      windDirectionDeg: windDirection,
      droneHeading: heading,
    });
    setAirSpeed(airSpeed);
  }, [weatherData, telemetryData?.ground_speed_m_s, telemetryData?.attitude_euler.yaw_deg]);

  const updateSpeedUnitAndFormat = (value: number | undefined): string => {
    // this is where we could have the user setting determine the unit
    if (value === undefined) {
      return '-';
    }
    const displayUnit: string = 'mph';
    let convertedValue;
    // this is just here to future proof the code for when we add more units
    switch (displayUnit) {
      case 'm/s':
        convertedValue = value;
        break;
      case 'km/h':
        convertedValue = convertSpeed({
          speed: value,
          fromUnit: SpeedUnit.METERS_PER_SECOND,
          toUnit: SpeedUnit.KILOMETERS_PER_HOUR,
        });
        break;
      case 'mph':
        convertedValue = convertSpeed({
          speed: value,
          fromUnit: SpeedUnit.METERS_PER_SECOND,
          toUnit: SpeedUnit.MILES_PER_HOUR,
        });
        break;
      default:
        return '-';
    }
    return roundToDecimalPlaces(convertedValue, 2).toFixed(2);
  };

  const groundSpeed = telemetryData?.ground_speed_m_s ? updateSpeedUnitAndFormat(telemetryData.ground_speed_m_s) : '-';

  const absolute_altitude_m = telemetryData?.position.absolute_altitude_m
    ? telemetryData.position.absolute_altitude_m
    : null;
  const terrainAltitude = telemetryStore.terrainAltitudeMeters;
  let altitudeFeet = null;
  if (absolute_altitude_m && terrainAltitude) {
    altitudeFeet = UnitConversions.mToF(absolute_altitude_m - terrainAltitude);
  }

  const processedData = telemetryData
    ? {
        relativeAltitude: altitudeFeet !== null ? roundToDecimalPlaces(altitudeFeet, 2).toFixed(2) : '-',
        groundSpeed,
        remainingFlightTime:
          telemetryData.remaining_flight_time !== undefined
            ? roundToDecimalPlaces(telemetryData.remaining_flight_time, 5).toString()
            : '-',
        latitude:
          telemetryData.position.latitude_deg !== undefined
            ? roundToDecimalPlaces(telemetryData.position.latitude_deg, 5).toString()
            : '-',
        longitude:
          telemetryData.position.longitude_deg !== undefined
            ? roundToDecimalPlaces(telemetryData.position.longitude_deg, 5).toString()
            : '-',
      }
    : {};
  console.log('processedData', processedData);
  return (
    <div className='flex  w-full'>
      <div className='flex flex-col gap-2  justify-center'>
        <div>
          <DataLabel
            id='gs'
            label='Ground Speed:'
            value={processedData.groundSpeed}
            unit='mph'
            width='w-18'
            alignRight
          />
        </div>
        <div>
          <DataLabel id='as' label='Air Speed:' value={updateSpeedUnitAndFormat(airSpeed)} unit='mph' width='w-12' />
        </div>
      </div>
      <AttitudeIndicator
        size='110px'
        roll={telemetryData?.attitude_euler.roll_deg}
        pitch={telemetryData?.attitude_euler.pitch_deg}
        showBox={false}
      />
      <div className='flex flex-col gap-2  justify-center'>
        <div>
          <DataLabel id='agl' label='AGL:' value={processedData.relativeAltitude} unit='ft' width='w-18' alignRight />
        </div>
        <div className='h-6'></div> {/* Spacer to push AGL down for alignment */}
      </div>
    </div>
  );
};
export default observer(AttitudeContainer);
