import { TelemetryService } from '../../services/TelemetryService';
import DirectionButton, { Direction } from './DirectionButton';

const CameraDirectionPad = () => {
  const ANGLE_STEP = 5;

  const handleMoveGimbal = async (pitch: number, yaw: number) => {
    console.log('Moving gimbal', pitch, yaw);
    try {
      await TelemetryService.moveGimbal(pitch, yaw);
    } catch (error) {
      console.error('Error moving gimbal', error);
    }
  };

  const handleSetGimbal = async (pitch: number, yaw: number) => {
    console.log('Setting gimbal angle', pitch, yaw);
    try {
      await TelemetryService.setGimbal(pitch, yaw);
    } catch (error) {
      console.error('Error setting gimbal', error);
    }
  };

  const handleButtonClick = (direction: Direction) => {
    switch (direction) {
      case Direction.UP:
        handleMoveGimbal(ANGLE_STEP, 0);
        break;
      case Direction.DOWN:
        handleMoveGimbal(-ANGLE_STEP, 0);
        break;
      case Direction.LEFT:
        handleMoveGimbal(0, -ANGLE_STEP);
        break;
      case Direction.RIGHT:
        handleMoveGimbal(0, ANGLE_STEP);
        break;
      case Direction.UP_LEFT:
        handleMoveGimbal(ANGLE_STEP, -ANGLE_STEP);
        break;
      case Direction.UP_RIGHT:
        handleMoveGimbal(ANGLE_STEP, ANGLE_STEP);
        break;
      case Direction.DOWN_LEFT:
        handleMoveGimbal(-ANGLE_STEP, -ANGLE_STEP);
        break;
      case Direction.DOWN_RIGHT:
        handleMoveGimbal(-ANGLE_STEP, ANGLE_STEP);
        break;
      case Direction.CENTER:
        handleSetGimbal(0, 0);
        break;
      default:
        break;
    }
  };
  const commonButtonStyles = ''; // Define common button styles if needed
  return (
    <div className='grid grid-rows-3 grid-cols-3 gap-2 m-auto'>
      <DirectionButton handleClick={handleButtonClick} direction={Direction.UP_LEFT} className={commonButtonStyles} />
      <DirectionButton handleClick={handleButtonClick} direction={Direction.UP} className={commonButtonStyles} />
      <DirectionButton handleClick={handleButtonClick} direction={Direction.UP_RIGHT} className={commonButtonStyles} />
      <DirectionButton handleClick={handleButtonClick} direction={Direction.LEFT} className={commonButtonStyles} />
      <DirectionButton handleClick={handleButtonClick} direction={Direction.CENTER} className={commonButtonStyles} />
      <DirectionButton handleClick={handleButtonClick} direction={Direction.RIGHT} className={commonButtonStyles} />
      <DirectionButton handleClick={handleButtonClick} direction={Direction.DOWN_LEFT} className={commonButtonStyles} />
      <DirectionButton handleClick={handleButtonClick} direction={Direction.DOWN} className={commonButtonStyles} />
      <DirectionButton
        handleClick={handleButtonClick}
        direction={Direction.DOWN_RIGHT}
        className={commonButtonStyles}
      />
    </div>
  );
};

export default CameraDirectionPad;
