/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { ColorPaletteProp } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import BlockIcon from '@mui/icons-material/Block';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Page from './Page';
import { Link as RouterLink } from 'react-router-dom';

const rows = [
  {
    id: 560,
    name: 'Drone-6d7866',
    location: 'Texas',
    batteryLevel: 83,
    maxFlightTime: 34,
    maxSpeed: 24,
    maxAltitude: 381,
    maxDistance: 1665,
    status: 'maintenance',
    image: 'https://dummyimage.com/600x400/000/fff&text=Drone-9293',
  },
  {
    id: 277,
    name: 'Drone-70db4e',
    location: 'New York',
    batteryLevel: 85,
    maxFlightTime: 23,
    maxSpeed: 38,
    maxAltitude: 230,
    maxDistance: 1829,
    status: 'available',
    image: 'https://dummyimage.com/600x400/000/fff&text=Drone-2a95',
  },
  {
    id: 208,
    name: 'Drone-b5d494',
    location: null,
    batteryLevel: 100,
    maxFlightTime: 20,
    maxSpeed: 39,
    maxAltitude: 281,
    maxDistance: 942,
    status: 'available',
    image: 'https://dummyimage.com/600x400/000/fff&text=Drone-98bd',
  },
  {
    id: 190,
    name: 'Drone-1680ce',
    location: null,
    batteryLevel: 97,
    maxFlightTime: 16,
    maxSpeed: 34,
    maxAltitude: 334,
    maxDistance: 1122,
    status: 'on-mission',
    image: 'https://dummyimage.com/600x400/000/fff&text=Drone-0a63',
  },
  {
    id: 139,
    name: 'Drone-6f9879',
    location: 'New York',
    batteryLevel: null,
    maxFlightTime: 23,
    maxSpeed: 33,
    maxAltitude: 212,
    maxDistance: 522,
    status: 'lost',
    image: 'https://dummyimage.com/600x400/000/fff&text=Drone-4d99',
  },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function Inventory() {
  const [order, setOrder] = React.useState<Order>('desc');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [open, setOpen] = React.useState(false);
  const renderFilters = () => (
    <>
      <FormControl size='sm'>
        <FormLabel>Status</FormLabel>
        <Select size='sm' placeholder='Filter by status' slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}>
          <Option value='available'>Available</Option>
          <Option value='inactive'>Inactive</Option>
          <Option value='maintenance'>Maintenance</Option>
          <Option value='repair'>Repair</Option>
          <Option value='lost'>Lost</Option>
          <Option value='on-mission'>On Mission</Option>
        </Select>
      </FormControl>
    </>
  );

  return (
    <Page>
      <Sheet
        className='SearchAndFilters-mobile'
        sx={{
          display: { xs: 'flex', sm: 'none' },
          my: 1,
          gap: 1,
        }}
      >
        <Input size='sm' placeholder='Search' startDecorator={<SearchIcon />} sx={{ flexGrow: 1 }} />
        <IconButton size='sm' variant='outlined' color='neutral' onClick={() => setOpen(true)}>
          <FilterAltIcon />
        </IconButton>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby='filter-modal' layout='fullscreen'>
            <ModalClose />
            <Typography id='filter-modal' level='h2'>
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {renderFilters()}
              <Button color='primary' onClick={() => setOpen(false)}>
                Submit
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>
      <Box
        className='SearchAndFilters-tabletUp'
        sx={{
          borderRadius: 'sm',
          py: 2,
          display: { xs: 'none', sm: 'flex' },
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: { xs: '120px', md: '160px' },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size='sm'>
          <FormLabel>Search for drone</FormLabel>
          <Input size='sm' placeholder='Search' startDecorator={<SearchIcon />} />
        </FormControl>
        {renderFilters()}
      </Box>
      <Sheet
        className='OrderTableContainer'
        variant='outlined'
        sx={{
          display: { xs: 'none', sm: 'initial' },
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby='tableTitle'
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 140, padding: '12px 6px' }}>ID</th>
              <th style={{ width: 140, padding: '12px 6px' }}>Name</th>
              <th style={{ width: 140, padding: '12px 6px' }}>
                <Link
                  underline='none'
                  color='primary'
                  component='button'
                  onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
                  fontWeight='lg'
                  endDecorator={<ArrowDropDownIcon />}
                  sx={{
                    '& svg': {
                      transition: '0.2s',
                      transform: order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                    },
                  }}
                >
                  Status
                </Link>
              </th>
              <th style={{ width: 240, padding: '12px 6px' }}>Battery Level</th>
              <th style={{ width: 140, padding: '12px 6px' }}> </th>
            </tr>
          </thead>
          <tbody>
            {rows
              .slice()
              .sort(getComparator(order, 'status'))
              .map((row) => (
                <tr key={row.id}>
                  <td>
                    <Typography level='body-xs'>{row.id}</Typography>
                  </td>
                  <td>
                    <Typography level='body-xs'>{row.name}</Typography>
                  </td>
                  <td>
                    <Chip
                      variant='soft'
                      size='sm'
                      startDecorator={
                        {
                          available: <CheckRoundedIcon />,
                          inactive: <AutorenewRoundedIcon />,
                          maintenance: <BlockIcon />,
                          repair: <PrecisionManufacturingIcon />,
                          lost: <BlockIcon />,
                          'on-mission': <FlightTakeoffIcon />,
                        }[row.status]
                      }
                      color={
                        {
                          available: 'info',
                          inactive: 'neutral',
                          maintenance: 'warning',
                          repair: 'warning',
                          lost: 'danger',
                          'on-mission': 'success',
                        }[row.status] as ColorPaletteProp
                      }
                    >
                      {row.status}
                    </Chip>
                  </td>
                  <td>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                      <div>
                        <Typography level='body-xs'>{row.batteryLevel ? `${row.batteryLevel}%` : '-'}</Typography>
                      </div>
                    </Box>
                  </td>
                  <td>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                      {row.status === 'available' && (
                        <Link level='body-xs' component='button'>
                          <RouterLink to={`/mission-view2/${row.id}`}>Start Mission</RouterLink>
                        </Link>
                      )}
                    </Box>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Sheet>
      <Box
        className='Pagination-laptopUp'
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
      >
        <Button size='sm' variant='outlined' color='neutral' startDecorator={<KeyboardArrowLeftIcon />}>
          Previous
        </Button>

        <Box sx={{ flex: 1 }} />
        {['1', '2', '3', '…', '8', '9', '10'].map((page) => (
          <IconButton key={page} size='sm' variant={Number(page) ? 'outlined' : 'plain'} color='neutral'>
            {page}
          </IconButton>
        ))}
        <Box sx={{ flex: 1 }} />

        <Button size='sm' variant='outlined' color='neutral' endDecorator={<KeyboardArrowRightIcon />}>
          Next
        </Button>
      </Box>
    </Page>
  );
}
