import { useEffect, useRef, useState } from 'react';
import { CameraZoom, TelemetryService } from '../services/TelemetryService';
import { debounce } from 'lodash';

export interface VideoDisplayProps {
  stream: MediaStream | null;
  className?: string;
  style?: React.CSSProperties;
  showTargetButton?: boolean;
}

const VideoDisplay = ({ stream, className, style, showTargetButton = false }: VideoDisplayProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const horizontalFOV = 81; // Horizontal field of view in degrees
  const verticalFOV = 51.32; // Vertical field of view in degrees
  const [cameraZoom, setCameraZoom] = useState<CameraZoom>({ zoomLevel: 1.0 });
  const lastEventTime = useRef<number>(Date.now());
  let zoomLevel = useRef(10);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
      videoRef.current.play().catch(() => {});
    }
  }, [stream, videoRef.current]);

  const handleVideoClick = (event: React.MouseEvent<HTMLVideoElement>) => {
    const { clientX, clientY } = event;

    // Get the dimensions of the video element
    if (!videoRef.current) return;
    const videoAspectRatio = videoRef.current.videoWidth / videoRef.current.videoHeight;

    const { width, height, top, left } = videoRef.current.getBoundingClientRect();
    const containerAspectRatio = width / height;
    let effectiveWidth, effectiveHeight, offsetX, offsetY;

    // Compare the aspect ratios to determine the effective area
    if (containerAspectRatio > videoAspectRatio) {
      // Video has vertical bars (left and right)
      effectiveHeight = height;
      effectiveWidth = height * videoAspectRatio;
      offsetX = (width - effectiveWidth) / 2;
      offsetY = 0;
    } else {
      // Video has horizontal bars (top and bottom)
      effectiveWidth = width;
      effectiveHeight = width / videoAspectRatio;
      offsetX = 0;
      offsetY = (height - effectiveHeight) / 2;
    }

    // Adjust the click coordinates relative to the effective video area
    const effectiveX = clientX - left - offsetX;
    const effectiveY = clientY - top - offsetY;

    // Ensure the click is within the effective area
    if (effectiveX < 0 || effectiveX > effectiveWidth || effectiveY < 0 || effectiveY > effectiveHeight) {
      console.log('Click outside the effective video area');
      return;
    }

    // Calculate the offsets from the center
    const deltaX = clientX - width / 2;
    const deltaY = height / 2 - clientY; // Inverted y-axis in screen coordinates

    // Calculate angles
    const anglePerPixelX = horizontalFOV / width;
    const anglePerPixelY = verticalFOV / height;

    const angleX = deltaX * anglePerPixelX;
    const angleY = deltaY * anglePerPixelY;

    console.log(`Move gimbal to: ${angleX} degrees horizontal, ${angleY} degrees vertical`);

    TelemetryService.moveGimbal(angleY, angleX);
  };

  const debouncedUpdateCameraZoom = debounce((zoomLevel: number) => {
    TelemetryService.updateCameraZoom({ zoomLevel });
  }, 500);

  const analyzeZoomScroll = (event: WheelEvent) => {
    let step = 1; // Work in scaled integer space
    const y = event.deltaY;
    console.log(event.ctrlKey, y);

    if (y < 0) {
      if (zoomLevel.current <= 10) return; // Lower bound check, assuming minimum zoom level is 1.0
      zoomLevel.current -= step;
      console.log('Zooming out locally', zoomLevel.current / 10);
    } else {
      if (zoomLevel.current >= 60) return; // Upper bound check, assuming maximum zoom level is 6.0
      zoomLevel.current += step;
      console.log('Zooming in locally', zoomLevel.current / 10);
    }

    // Ensure bounds are maintained after adjustments
    zoomLevel.current = Math.max(10, Math.min(zoomLevel.current, 60));

    // Convert back to normal scale for use elsewhere
    let normalizedZoomLevel = zoomLevel.current / 10;
    console.log('Zoom level:', normalizedZoomLevel);
    setCameraZoom({ zoomLevel: normalizedZoomLevel });
    debouncedUpdateCameraZoom(normalizedZoomLevel);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLVideoElement>) => {
    if (!videoRef.current) return;

    const { clientX, clientY } = event;
    const { width, height, top, left } = videoRef.current.getBoundingClientRect();
    const videoAspectRatio = videoRef.current.videoWidth / videoRef.current.videoHeight;
    const containerAspectRatio = width / height;
    let offsetX, offsetY, effectiveWidth, effectiveHeight;

    if (containerAspectRatio > videoAspectRatio) {
      effectiveHeight = height;
      effectiveWidth = height * videoAspectRatio;
      offsetX = (width - effectiveWidth) / 2;
      offsetY = 0;
    } else {
      effectiveWidth = width;
      effectiveHeight = width / videoAspectRatio;
      offsetX = 0;
      offsetY = (height - effectiveHeight) / 2;
    }

    const effectiveX = clientX - left - offsetX;
    const effectiveY = clientY - top - offsetY;

    if (effectiveX >= 0 && effectiveX <= effectiveWidth && effectiveY >= 0 && effectiveY <= effectiveHeight) {
      videoRef.current.style.cursor = 'crosshair';
    } else {
      videoRef.current.style.cursor = 'default';
    }
  };

  useEffect(() => {
    if (!videoRef.current) return;
    const videoElement = videoRef.current;
    if (videoElement) {
      const options = { passive: false }; // Specify the event listener as not passive
      videoElement.addEventListener('wheel', analyzeZoomScroll, options);

      // Cleanup function to remove the event listener when the component unmounts
      return () => {
        videoElement.removeEventListener('wheel', analyzeZoomScroll);
      };
    }
  }, [videoRef.current]);

  return (
    <div className='relative '>
      <span className='absolute top-0 left-0 m-10'>{cameraZoom.zoomLevel}x</span>
      <video
        ref={videoRef}
        muted
        className={className}
        style={style}
        onClick={handleVideoClick}
        onMouseMove={handleMouseMove}
      ></video>
      ;
    </div>
  );
};

export default VideoDisplay;
