import { IconButton } from '@mui/joy';
import LocalDateTimeDisplay from './LocalDateTimeDisplay';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Link as RouterLink } from 'react-router-dom';
import LocalAddress from './LocalAddress';
import { AttitudeIndicator } from 'react-typescript-flight-indicators';

export interface MissionViewHeaderProps {
  droneName: string;
  addressString: string;
  lat: string | undefined;
  long: string | undefined;
  roll?: number;
  pitch?: number;
}

const MissionViewHeader = ({ droneName, addressString, lat, long, pitch = 0, roll = 0 }: MissionViewHeaderProps) => {
  return (
    <div className='flex justify-between items-center w-full h-full bg-midGray text-white pl-6 pr-6'>
      <div>
        <RouterLink to={'/inventory'}>
          <h1 className='text-1xl font-bold align-center flex'>
            <ArrowBackIosNewIcon />
            {droneName}
          </h1>
        </RouterLink>
      </div>
      <div>
        <LocalAddress lat={lat} long={long} />
      </div>
      <div>
        <LocalDateTimeDisplay lat={lat?.toString()} long={long?.toString()} />
      </div>
      <div className='filter grayscale'>
        <AttitudeIndicator size='110px' roll={roll} pitch={pitch} showBox={false} />
      </div>
    </div>
  );
};

export default MissionViewHeader;
