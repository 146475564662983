const login = async (username: string, password: string, csrfToken: string) => {
  const response = await fetch('/api/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrfToken,
    },
    body: JSON.stringify({ username, password }),
  });
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error('Login failed');
  }
};
const apiPing = async () => {
  const response = await fetch('/api/ping');
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error('Ping failed');
  }
};

const getCSRFToken = () => {
  return fetch('/api/getcsrf');
};

const getSession = () => {
  return fetch('/api/getsession').then((resp) => {
    return resp.json();
  });
};

const logout = () => {
  return fetch('/api/logout').then((resp) => {
    return resp.json();
  });
};

const getUser = () => {
  return fetch('/api/user-data').then((resp) => {
    return resp.json();
  });
};

export const SessionService = {
  login,
  apiPing,
  getCSRFToken,
  getSession,
  logout,
  getUser,
};
