import { roundToDecimalPlaces } from '../../../general-utilities/numbers-util';
import useGetLocalTime from '../../../hooks/useGetLocalTime';
import DataLabel from '../DataLabel';

interface LatLongComponentProps {
  lat: number;
  long: number;
}
const LatLongComponent = ({ lat, long }: LatLongComponentProps) => {
  const { localDate } = useGetLocalTime();

  if (lat === undefined || long === undefined) {
    return null;
  }
  const roundedLat = roundToDecimalPlaces(lat, 5).toString();
  const roundedLong = roundToDecimalPlaces(long, 5).toString();
  return (
    <div className='flex gap-8 flex-row '>
      <div>
        <DataLabel id='lat' label='Date:' width='' value={localDate} />
      </div>
      <div>
        <DataLabel id='lat' label='Lat:' width='' value={roundedLat} />
      </div>
      <div>
        <DataLabel id='long' label='Lon:' value={roundedLong} />
      </div>
    </div>
  );
};

export default LatLongComponent;
