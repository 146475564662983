import OpenMeteoData from '../models/Weather';

const getTelementryData = () => {
  return fetch('/telemetry').then((resp) => {
    return resp.json();
  });
};

const getTimeZoneFromLatLong = (lat: number, long: number) => {
  const TIMEZONE_API_KEY = '2AQOY8TY6SKZ';
  const url = `https://api.timezonedb.com/v2.1/get-time-zone?key=${TIMEZONE_API_KEY}&format=json&by=position&lat=${lat}&lng=${long}`;
  return fetch(url).then((res) => res.json());
};

const getAddressFromLatLong = (lat: string, long: string) => {
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${long},${lat}.json?access_token=pk.eyJ1IjoianVsaWFub2VzIiwiYSI6ImNrOGNwbXVzbTA2bnMzZW95bmhvemIwbGkifQ.vHzrkhopow_IrtZm5yP5iA`;
  return fetch(url).then((res) => res.json());
};

const moveGimbal = (pitch: number, yaw: number) => {
  const url = `/gimbal/angle_step/${pitch}/${yaw}`;
  return fetch(url).then((res) => res.json());
};

const setGimbal = (pitch: number, yaw: number) => {
  const url = `/gimbal/angle/${pitch}/${yaw}`;
  return fetch(url).then((res) => res.json());
};

const getWeatherData = (lat: number | string, long: number | string): Promise<OpenMeteoData> => {
  const url = `https://api.open-meteo.com/v1/forecast?latitude=${lat}&longitude=${long}&current=temperature_2m,wind_speed_10m,wind_gusts_10m,wind_direction_10m`;
  return fetch(url).then((resp) => {
    return resp.json();
  });
};

// TODO add some error handling for all these fetches

export interface CameraZoom {
  zoomLevel: number;
}

const isInCameraZoomRange = (zoom: number) => {
  return zoom >= 1 && zoom <= 6;
};

const updateCameraZoom = ({ zoomLevel }: CameraZoom) => {
  if (!isInCameraZoomRange(zoomLevel)) {
    throw new Error('Invalid camera zoom level');
  }
  const floatZoomStr = Number(zoomLevel).toFixed(1);
  const url = `/camera/zoom/${floatZoomStr}`;
  return fetch(url).then((res) => res.json());
};

export const TelemetryService = {
  getTelementryData,
  getTimeZoneFromLatLong,
  getAddressFromLatLong,
  moveGimbal,
  setGimbal,
  getWeatherData,
  updateCameraZoom,
};
