import Button from '@mui/joy/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Link as RouterLink } from 'react-router-dom';
import BatteryGauge from 'react-battery-gauge';
import telemetryStore from '../../stores/TelemetryStore';
import MissionTimer from './MissionTimer';
import Telemetry from '../../models/Telemetry';
import AttitudeContainer from './Attitude/AttitudeContainer';
import LocationContainer from './Location/LocationContainer';
import { useEffect, useRef, useState } from 'react';
import missionTimeStore from '../../stores/MissionTimeStore';
import { TelemetryService } from '../../services/TelemetryService';

export interface MissionViewHeaderProps {
  droneName: string;
  addressString: string;
  lat: string | undefined;
  long: string | undefined;
  roll?: number;
  pitch?: number;
}

interface BatteryProps {
  telemetryData: Telemetry;
  parentHeight?: number;
  fontSize?: number;
  size?: number;
}

const Battery = ({ telemetryData, fontSize = 20, size = 60 }: BatteryProps) => {
  return (
    <BatteryGauge
      size={size}
      value={telemetryData.battery.remaining_percent}
      customization={{
        batteryBody: {
          strokeWidth: 3,
          strokeColor: '#fff',
        },
        batteryCap: {
          fill: 'none',
          strokeWidth: 3,
          strokeColor: '#fff',
          cornerRadius: 2,
          capToBodyRatio: 0.4,
        },
        batteryMeter: {
          lowBatteryValue: 15,
          lowBatteryFill: 'red',
          outerGap: 1,
          noOfCells: 1, // more than 1, will create cell battery
          interCellsGap: 1,
        },
        readingText: {
          lightContrastColor: '#fff',
          darkContrastColor: '#fff',
          lowBatteryColor: 'red',
          fontFamily: 'Helvetica',
          fontSize: fontSize,
          showPercentage: true,
        },
      }}
    />
  );
};

const MissionViewHeaderV2 = ({ droneName }: MissionViewHeaderProps) => {
  const { telemetryData } = telemetryStore;
  const { latitude_deg, longitude_deg } = telemetryData?.position || {};
  const containerRef = useRef<HTMLDivElement>(null);

  const [batterySize, setBatterySize] = useState<number>(60);
  const [batteryFontSize, setBatteryFontSize] = useState<number>(20);

  const updateBatterySize = () => {
    if (containerRef.current) {
      const parentHeight = containerRef.current.offsetHeight;
      if (parentHeight) {
        setBatterySize(parentHeight * 0.5);
        const calculatedFontSize = parentHeight * 0.1;
        if (calculatedFontSize < 20) {
          setBatteryFontSize(20);
        } else {
          setBatteryFontSize(calculatedFontSize);
        }
      }
    }
  };

  useEffect(() => {
    if (latitude_deg && longitude_deg) {
      missionTimeStore.loadTimezone(latitude_deg, longitude_deg);
    }
  }, [latitude_deg, longitude_deg]);

  useEffect(() => {
    if (containerRef.current) {
      // TODO: add a debounce to this
      const resizeObserver = new ResizeObserver((entries) => {
        updateBatterySize();
      });

      resizeObserver.observe(containerRef.current);

      return () => resizeObserver.disconnect(); // Clean up observer on component unmount
    }
  }, [containerRef.current]);

  return (
    <div
      className='flex justify-between items-center w-full h-full bg-midGray text-white pl-6 pr-6 3xl:text-hugeSm 4xl:text-hugeMd 5xl:text-hugeLg'
      ref={containerRef}
    >
      <div className='flex flex-col'>
        <RouterLink to={'/inventory'}>
          <h1 className='text-1xl font-bold align-center flex items-center'>
            <ArrowBackIosNewIcon />
            <span>{droneName}</span>
          </h1>
        </RouterLink>
        {telemetryData?.battery.remaining_percent && (
          <div className='pt-4 ml-6'>
            <Battery telemetryData={telemetryData} size={batterySize} fontSize={batteryFontSize} />
          </div>
        )}
      </div>
      <div>
        <Button component='button'>
          <RouterLink to={`/mission-comms`} target='_blank' className='3xl:text-hugeSm 4xl:text-hugeMd 5xl:text-hugeLg'>
            Mission Comms
          </RouterLink>
        </Button>
      </div>
      <div>
        <LocationContainer />
      </div>
      <div>
        <div className='flex flex-col gap-2'>
          <MissionTimer />
        </div>
      </div>
      <div className=' p-12 flex '>
        <AttitudeContainer />
      </div>
    </div>
  );
};

export default MissionViewHeaderV2;
