import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

export interface PageProps {
  children: React.ReactNode;
}

const Page = ({ children }: PageProps) => {
  return (
    <div className='h-lvh'>
      <Header />
      <div className='max-w-8xl mx-auto px-4 sm:px-6 md:px-8'>{children}</div>
      <div className='max-w-8xl mx-auto px-4 sm:px-6 md:px-8'>
        <Footer />
      </div>
    </div>
  );
};

export default Page;
